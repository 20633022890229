export const unixToDate = unix => {
  var a = new Date(unix * 1000);
  var months = [
    "jan.",
    "feb.",
    "mar.",
    "apr.",
    "maj.",
    "jun.",
    "jul.",
    "aug.",
    "sep.",
    "okt.",
    "nov.",
    "dec."
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = `${date}. ${month} ${year}`;
  return time;
};

export const unixToTime = unix => {
  var a = new Date(unix * 1000);
  var hour = a.getHours();
  var min = a.getMinutes();
  if (min.toString().length === 1) min = `0${min}`;
  var time = `${hour}:${min}`;
  return time;
};
