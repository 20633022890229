import React, { Component } from 'react';
import _ from 'lodash';

class pagination extends Component {
    pageStyle(item) {
        let classes = 'page-item';
        classes += this.props.pageNumber - 1 === item ? ' active' : null;
        return classes;
    }

    render() { 
        if(this.props.totalPages > 1)
        return (
            <div className="row">
                <div className="col-md-12">
                    <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                            <li className="page-item"><a className="page-link" onClick={() => this.props.minusClick()}>{`<`}</a></li>
                                {_.times(this.props.totalPages).map(res =>
                                    <li className={this.pageStyle(res)} key={res}><a className="page-link" onClick={() => this.props.pageClick(res + 1)}>{res + 1}</a></li>
                                )}
                            <li className="page-item"><a className="page-link" onClick={() => this.props.plusClick()}>{`>`}</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        );
        return null;
    }
}
 
export default pagination;