import musik from '../images/logoer/PindleMusik.svg';
import kultur from '../images/logoer/PindleKultur.svg';
import udstilling from '../images/logoer/PindleUdstilling.svg';
import detail from '../images/logoer/PindleDetail.svg';
import born from '../images/logoer/PindleBorn.svg';
import sport from '../images/logoer/PindleSport.svg';
import byen from '../images/logoer/PindleByen.svg';
import viden from '../images/logoer/PindleViden.svg';
import natur from '../images/logoer/PindleNatur.svg';
import neutral from '../images/logoer/PindleNeutral.svg';

export const categories = [
    {id: 1, name: "Musik", color: "#9c27b0", icon: musik},
    {id: 2, name: "Kultur", color: "#e83834", icon: kultur},
    {id: 3, name: "Udstiling", color: "#4caf50", icon: udstilling},
    {id: 4, name: "Detail", color: "#795548", icon: detail},
    {id: 5, name: "For børn", color: "#00b2bf", icon: born},
    {id: 6, name: "Sport", color: "#3f51b5", icon: sport},
    {id: 7, name: "I Byen", color: "#ff5722", icon: byen},
    {id: 8, name: "Viden", color: "#ec407a", icon: viden},
    {id: 9, name: "Natur", color: "#00b6ff", icon: natur},
    {id: 10, name: "Neutral", color: "#cccccc", icon: neutral}
];

export const getCategory = (id) => {
    if(id <= categories.length) return categories.find(res => res.id === id);
    return categories[9];
}