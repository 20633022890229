import React, { Component } from "react";
import { getCategory } from '../actions/categories';
import { unixToDate, unixToTime } from '../actions/unix';
import price from '../actions/price';

class focus extends Component {
  render() {
    if (this.props.pickEvent !== null) 
      return (
        <div className="row">
        <div className="col-12">
          <div className="focus-box row no-gutters">
            <div className="focus-box-left col-sm-12 col-md-6" style={{ backgroundColor: getCategory(parseInt(this.props.pickEvent.main_category_id)).color }}>
              <div className="focus-img">
                <div className="focus-category-logo">
                  <img
                    src={getCategory(parseInt(this.props.pickEvent.main_category_id)).icon}
                    alt={getCategory(parseInt(this.props.pickEvent.main_category_id)).name}
                  />
                </div>
                <img
                  src={this.props.pickEvent.images.detail.large}
                  alt={this.props.pickEvent.title}
                />
              </div>
              <div className="focus-info m-4">
                <div className="focus-date">
                  <div className="focus-date-icon" />
                  <h3>{unixToDate(this.props.pickEvent.start_date)}</h3>
                </div>
                <div className="focus-time">
                  <div className="focus-time-icon" />
                  <h3>{unixToTime(this.props.pickEvent.start_date)}</h3>
                </div>
                <div className="focus-price">
                  <div className="focus-price-icon" />
                  <h3 dangerouslySetInnerHTML={{ __html: price(this.props.pickEvent.price) }}></h3>
                </div>
                <div className="focus-venue">
                  <div className="focus-venue-icon" />
                  <h3>{this.props.pickVenue}</h3>
                </div>
                <div className="focus-place">
                  <div className="focus-place-icon" />
                  <h3>{this.props.pickEvent.location_info}</h3>
                </div>
                <div className="focus-ticket">
                  {this.props.pickEvent.ticket_description ? 
                  <div>
                    <div className="focus-ticket-icon" />
                    <h3><a href={this.props.pickEvent.ticket_link} target="_blank">{this.props.pickEvent.ticket_description}</a></h3></div> : null
                  }
                </div>
                <div className="focus-link">
                  {this.props.pickEvent.website2_description ?
                  <div>
                    <div className="focus-website-icon" />
                    <h3><a href={this.props.pickEvent.website2_link} target="_blank">{this.props.pickEvent.website2_description}</a></h3> </div> : null
                  }
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="focus-text m-3">
                <div className="focus-close-icon" onClick={() => this.props.clearFocus()}></div>
                <h5><div className="category">{getCategory(parseInt(this.props.pickEvent.main_category_id)).name}</div></h5>
                <h1>{this.props.pickEvent.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: this.props.pickEvent.description }} />
              </div><br />
            </div>
          </div>
        </div>
        </div>
      );
    else return null;
  }
}

export default focus;
