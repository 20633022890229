import React, { Component } from "react";
import axios from 'axios';
import * as qs from 'query-string';
import "./App.css";
import Header from "./components/header";
import Focus from "./components/focus";
import Events from "./components/events";
import Pagination from './components/pagination';
// import Footer from "./components/footer";

// Query options
const parsed = qs.parse(window.location.search);
const language = parsed.language ? parsed.language : "da";
const timestamp = parsed.timestamp ? parsed.timestamp : 0;
const startTime = parsed.startTime ? parsed.startTime : Math.floor(Date.now() /1000);
const perPage = parsed.perPage ? parsed.perPage : 20;
const city = parsed.city ? parsed.city : '';
const pageNumber = parsed.pageNumber ? parsed.pageNumber : 1;
const auth = parsed.auth ? "Token token=" + parsed.auth : "Token token=fc2af6ef007d0da9d76d2ebfb171370387e9b9d2f4e28fa216d30e53e39256b4"; // Production
const header = parsed.header ? parsed.header : "Det sker";
const websiteText = parsed.websiteText ? parsed.websiteText : "pindle.viborg.dk";
const websiteUrl = parsed.websiteUrl ? parsed.websiteUrl : "http://pindle.viborg.dk";
const appText = parsed.appText ? parsed.appText : "Download App";
const appUrl = parsed.appUrl ? parsed.appUrl : "https://itunes.apple.com/us/app/pindle-events-og-begivenheder-lige-ved-h%C3%A5nden/id1033080265?mt=8";
const venue = parsed.venue ? parsed.venue : "";
const search = parsed.search ? parsed.search : "";
const category = parsed.category ? parsed.category : "";

class App extends Component {
  state = {
    api: {
      language: language,
      timestamp: timestamp,
      startTime: startTime,
      perPage: perPage,
      city: city,
      venue: venue,
      search: search,
      category: category,
      auth: auth
    },
    totalPages: Number,
    pageNumber: pageNumber,
    settings: {
      headerText: header,
      website: {
        url: websiteUrl,
        text: websiteText,
      },
      app: {
        url: appUrl,
        text: appText
      }
    },
    data: [],
    meta: [],
    pick: null,
    venue: null 
  };

  // CORS-anywhere is added!
  componentDidMount() {
    const { language, timestamp, startTime, perPage, city, auth, venue, search, category } = this.state.api;

    axios
      .get(
        `https://mb-cors.herokuapp.com/https://admin.pindle.dk/api/v2/events?language=${language}&timestamp=${timestamp}&start_time=${startTime}&per_page=${perPage}&page_no=${this.state.pageNumber}&city=${city}&venue=${venue}&search=${search}&category=${category}`,
        {
          headers: {
            Authorization: auth
          }
        }
      )
      .then(res => {
        const data = res.data.data.events;
        this.setState({ data });
        const totalPages = res.data.data.total_pages;
        this.setState({ totalPages });
      })
      .catch(err => {
        console.log(err, 'Axios eror');
      });

    axios
      .get(
        "https://mb-cors.herokuapp.com/https://admin.pindle.dk/api/v2/meta_data?language=da&timestamp=0&areas=1&mode=compact",
        {
          headers: {
            Authorization: auth
          }
        }
      )
      .then(res => {
        const meta = res.data.data;
        this.setState({ meta });
      }).catch(err => {
        console.log(err, 'Axios error')
      });

  }

  handlePickEvent = (pick, venue) => {
    this.setState({ pick });
    this.setState({ venue });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
        this.setState({ data: [] });
        this.componentDidMount();
    }
  }

  clearFocus = () => {
    this.setState({ pick: null });
  }

  plusClick = () => {
    const newPageNumber = this.state.pageNumber + 1;
    if (newPageNumber <= this.state.totalPages) this.setState({ pageNumber: newPageNumber });
    return;
  };

  minusclick = () => {
    const newPageNumber = this.state.pageNumber - 1;
    if (newPageNumber > 0) this.setState({ pageNumber: newPageNumber });
    return;
  };

  pageClick = page => {
    this.setState({ pageNumber: page });
  };

  render() {
    return (
      <div className="App container">
          <Focus 
            pickEvent={this.state.pick} 
            pickVenue={this.state.venue} 
            clearFocus={this.clearFocus}
          />        
          <Header 
            settings={this.state.settings} 
          />
          <Events
            api={this.state.api}
            data={this.state.data}
            meta={this.state.meta}
            onApplyClick={this.handlePickEvent}
          />
          <Pagination 
            pageNumber={this.state.pageNumber}
            totalPages={this.state.totalPages}
            plusClick={this.plusClick}
            minusClick={this.minusclick}
            pageClick={this.pageClick}
          />
        {/* <Footer 
          settings={this.state.settings} 
        /> */}
      </div>
    );
  }
}

export default App;
