import React, { Component } from "react";
import { unixToDate, unixToTime } from "../actions/unix";
import { getVenueName } from '../actions/venues';
import { getCategory } from '../actions/categories';

class events extends Component {

loader() {
  if (this.props.data.length === 0) return <div className="loader"></div>;
  return null;
}

render() {
  return (
      <div className="row">
        {this.loader()}
        {this.props.data.map(res => (
          <div key={res.id} className="col-md-12 col-lg-6">
            <div 
              className="event-box row no-gutters"
              onClick={() => this.props.onApplyClick(res, getVenueName(res.venue_id, this.props.meta.venues))
            }>
              <div className="col-sm-12 col-md-7">
                <div className="event-box-img">
                  <div className="event-category-logo">
                    <img 
                      src={getCategory(parseInt(res.main_category_id)).icon} 
                      alt={getCategory(parseInt(res.main_category_id)).name}
                    />
                  </div>
                  <img 
                    src={res.images.detail ? res.images.detail.large : null}
                    alt={res.title}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-5">
                <div className="event-box-text m-3">
                  <h2>{res.title}</h2>
                  <div className="event-box-info">
                    <h3>
                      <div className="place-icon" />
                      {getVenueName(res.venue_id, this.props.meta.venues)}
                    </h3>
                    <h3>
                      <div className="date-icon" />
                      {unixToDate(res.start_date)}
                    </h3>
                    <h3>
                      <div className="time-icon" />
                      {unixToTime(res.start_date)}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      );
  }
}

export default events;
