import React, { Component } from 'react'; 

class header extends Component {
    render() { 
        return ( 
            <div className="row">
                <div className="col-md-12">
                    <h1>{this.props.settings.headerText}</h1>
                </div>
            </div>
        );
    }
}
 
export default header;